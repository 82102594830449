export const VUE_APP_PASSPORT_URL = process.env.VUE_APP_PASSPORT_URL || 'https://passport.mpk-int-dev.ru';
export const VUE_APP_PASSPORT_SECRET = process.env.VUE_APP_PASSPORT_SECRET || 'secret';
export const VUE_APP_PASSPORT_EXTERNAL_URL = process.env.VUE_APP_PASSPORT_EXTERNAL_URL || 'https://passport.mpk-int-dev.ru';
export const VUE_APP_PASSPORT_EXTERNAL_SECRET = process.env.VUE_APP_PASSPORT_EXTERNAL_SECRET || 'secret';
export const VUE_APP_PASSPORT_TG_ID_ATTR = process.env.VUE_APP_PASSPORT_TG_ID_ATTR || 'aeabf982-fa76-4d0f-a973-6f8eadb8db41';
export const VUE_APP_PASSPORT_TG_LOGIN_ATTR = process.env.VUE_APP_PASSPORT_TG_LOGIN_ATTR || 'b5322d78-a463-4fb9-ac46-3e256f6fc813';
export const VUE_APP_PASSPORT_VPN_ATTR = process.env.VUE_APP_PASSPORT_VPN_ATTR || '1150365e-6e5d-4705-bf9f-11fc037bc804';
export const VUE_APP_PASSPORT_BRANCHES_ATTR = process.env.VUE_APP_PASSPORT_BRANCHES_ATTR || 'ba39f16f-cb2a-4960-8395-95d7c842610b';
export const VUE_APP_HELPDESK_API_URL = process.env.VUE_APP_HELPDESK_API_URL || 'https://helpdesk.legal-dev.ru';
export const VUE_APP_HELPDESK_URL = process.env.VUE_APP_HELPDESK_URL || 'https://helpdesk-front.legal-dev.ru';
export const VUE_APP_HELPBACK_URL = process.env.VUE_APP_HELPBACK_URL || 'https://help-back.legal-dev.ru';
export const VUE_APP_TGBOTS_URL = process.env.VUE_APP_TGBOTS_URL || 'https://tg-bots.legal-dev.ru';
export const VUE_APP_OKK_API_URL = process.env.VUE_APP_OKK_URL || 'https://obs-viewer-be-nginx.legal-dev.ru';
export const VUE_APP_TERM_DOCGEN_URL = process.env.VUE_APP_TERM_DOCGEN_URL || 'https://term-docgen.legal-dev.ru';
export const VUE_APP_ADV_CAB_URL = process.env.VUE_APP_ADV_CAB_URL || 'http://127.0.0.1:3000';
export const VUE_APP_ADV_CAB_ROLE = process.env.VUE_APP_ADV_CAB_ROLE || 'adv_cabinet_role';

export function GET_PASSPORT_URL() {
	let passport = localStorage.getItem('passport');
	switch (passport) {
	case 'passport':
		return VUE_APP_PASSPORT_URL;
	case 'passport-external':
		return VUE_APP_PASSPORT_EXTERNAL_URL;
	default:
		return VUE_APP_PASSPORT_URL;
	}
}

export function IS_EXTERNAL() {
	let passport = localStorage.getItem('passport');
	switch (passport) {
	case 'passport':
		return false;
	case 'passport-external':
		return true;
	default:
		return false;
	}
}

export function GET_PASSPORT_SECRET() {
	let passport = localStorage.getItem('passport');
	switch (passport) {
	case 'passport':
		return VUE_APP_PASSPORT_SECRET;
	case 'passport-external':
		return VUE_APP_PASSPORT_EXTERNAL_SECRET;
	default:
		return VUE_APP_PASSPORT_SECRET;
	}
}
