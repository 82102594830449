<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Изменение данных филиала
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-form-group id="project-input-group" label="Проект филиала" v-if="(haveAllPermissions || canUpdateBflProject)" label-for="branch-project-input">
				<b-form-checkbox-group
						id="branch-project-input"
						name="branch-project-input"
						v-model="branch.project"
						:options="projectOptions"
				></b-form-checkbox-group>
			</b-form-group>

			<b-form-group id="name-input-group" label="Название филиала" label-for="branch-name-input">
				<b-form-input
						id="branch-name-input"
						name="branch-name-input"
						v-model="$v.branch.name.$model"
						:state="validateState('name')"
						aria-describedby="input-branch-name-live-feedback"
						placeholder="Введите название филиала"
						:disabled="!haveAllPermissions"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-name-live-feedback"
				>Обязательное поле. Название должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="status-input-group" v-if="haveAllPermissions" label="Статус филиала" label-for="branch-status-input">
				<b-form-select
						id="branch-status-input"
						name="branch-status-input"
						v-model="$v.branch.status.$model"
						:options="statuses"
						:state="validateState('status')"
						aria-describedby="input-branch-status-live-feedback"
				></b-form-select>
				<b-form-invalid-feedback
						id="input-branch-status-live-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-id-input-group" v-if="haveAllPermissions" label="AMO id" label-for="branch-amo-id-input">
				<b-form-input
						id="branch-amo-id-input"
						name="branch-amo-id-input"
						v-model="$v.branch.amo_id.$model"
						:state="validateState('amo_id')"
						aria-describedby="input-branch-amo-id-live-feedback"
						placeholder="Введите AMO id"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-amo-id-live-feedback"
				>AMO id должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="bfl-amo-id-input-group" v-if="haveAllPermissions" label="BFL AMO id" label-for="branch-bfl-amo-id-input">
				<b-form-input
						id="branch-bfl-amo-id-input"
						name="branch-bfl-amo-id-input"
						v-model="$v.branch.bfl_amo_id.$model"
						:state="validateState('bfl_amo_id')"
						aria-describedby="input-branch-bfl-amo-id-live-feedback"
						placeholder="Введите BFL AMO id"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-bfl-amo-id-live-feedback"
				>BFL AMO id должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-group-id-input-group" v-if="haveAllPermissions" label="ID группы AMO" label-for="branch-amo-group-id-input">
				<b-form-input
						id="branch-amo-group-id-input"
						name="branch-amo-group-id-input"
						v-model="$v.branch.amo_group_id.$model"
						:state="validateState('amo_group_id')"
						aria-describedby="input-branch-amo-group-id-live-feedback"
						placeholder="Введите ID группы АМО"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-amo-group-id-live-feedback"
				>ID AMO группы должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-group-name-input-group" v-if="haveAllPermissions" label="Название группы AMO" label-for="branch-amo-group-name-input">
				<b-form-input
						id="branch-amo-group-name-input"
						name="branch-amo-group-name-input"
						v-model="$v.branch.amo_group_name.$model"
						:state="validateState('amo_group_name')"
						aria-describedby="input-branch-amo-group-name-live-feedback"
						placeholder="Введите название группы AMO"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-amo-group-name-live-feedback"
				>Название группы AMO должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="city-id-input-group" v-if="haveAllPermissions" label="Новый город" label-for="branch-city-id-input">
				<b-form-select
						id="branch-city-id-input"
						v-model="$v.branch.city_id.$model"
						:state="validateState('city_id')"
						:options="newCityOptions"
						@change="findAddressCity"
				></b-form-select>
			</b-form-group>

			<b-form-group id="city-id-input-group" v-if="haveAllPermissions" label="Старый город" label-for="branch-city-id-input">
				<b-form-select
						id="branch-city-id-input"
						v-model="$v.branch.old_city_id.$model"
						:state="validateState('old_city_id')"
						:options="oldCityOptions"
						@change="findAddressCity"
				></b-form-select>
			</b-form-group>

			<b-form-group id="date-open-input-group" v-if="haveAllPermissions" label="Дата открытия" label-for="branch-date-open-input">
				<b-form-datepicker
						id="branch-date-open-input"
						v-model="branch.date_open"
						placeholder="Введите дату открытия"
						value-as-date
				></b-form-datepicker>
			</b-form-group>

			<div hidden class="form-group">
				телефоны
			</div>

			<b-form-group id="work-hours-input-group" v-if="haveAllPermissions || canUseHelpdeskChangeConsultation" label="Часы работы" label-for="branch-work-hours-input">
				<b-form-input
						id="branch-work-hours-input"
						name="branch-work-hours-input"
						v-model="branch.work_hours"
						placeholder="Введите часы работы"
				></b-form-input>
			</b-form-group>

			<b-form-group id="address-input-group" v-if="haveAllPermissions" label="Адрес" label-for="branch-address-modal-group">
				<b-button class="btn btn-sm btn-secondary" v-b-modal="'address-modal'">Адрес</b-button>
				<b-modal
						id="address-modal"
						title="Ввод адреса"
						ok-title="Применить"
						cancel-title="Отмена"
						@ok="generateAddress"
				>
					<b-form>
						<b-form-group id="branch-city-modal-house" label="Город" label-for="address-city-input">
							<b-form-input
									id="address-city-input"
									name="address-city-input"
									v-model="$v.address.city.$model"
									:state="validateAddressState('city')"
									placeholder="Введите название города"
									aria-describedby="address-city-input-feedback"
							></b-form-input>
							<b-form-invalid-feedback
									id="address-city-input-feedback"
							>Обязательное поле
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group id="branch-address-modal-street" label="Улица"
													label-for="address-street-cut-input">
							<b-row>
								<b-col>
									<b-form-select
											id="address-street-cut-input"
											name="address-street-cut-input"
											v-model="$v.address.street.cut.$model"
											:options="addressCutOptions"
									></b-form-select>
								</b-col>
								<b-col cols="9">
									<b-form-input
											id="address-street-input"
											name="address-street-input"
											v-model="$v.address.street.street.$model"
											:state="validateAddressState('street')"
											placeholder="Введите улицу/проспект"
											aria-describedby="address-street-input-feedback"
									></b-form-input>
									<b-form-invalid-feedback
											id="address-street-input-feedback"
									>Обязательное поле
									</b-form-invalid-feedback>
								</b-col>
							</b-row>
						</b-form-group>

						<b-form-group id="branch-address-modal-house" label="Дом" label-for="address-house-input">
							<b-form-input
									id="address-house-input"
									name="address-house-input"
									v-model="$v.address.house.$model"
									:state="validateAddressState('house')"
									placeholder="Введите номер дома"
									aria-describedby="address-house-input-feedback"
							></b-form-input>
							<b-form-invalid-feedback
									id="address-house-input-feedback"
							>Обязательное поле
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group id="branch-address-modal-corpus" label="Корпус" label-for="address-corpus-input">
							<b-form-input
									id="address-corpus-input"
									name="address-corpus-input"
									v-model="address.corpus"
									placeholder="Введите корпус, если есть"
							></b-form-input>
						</b-form-group>

						<b-form-group id="branch-address-modal-floor" label="Этаж" label-for="address-floor-input">
							<b-form-input
									id="address-floor-input"
									name="address-floor-input"
									v-model="address.floor"
									placeholder="Введите этаж, если необходимо"
							></b-form-input>
						</b-form-group>

						<b-form-group id="branch-address-modal-office" label="Офис" label-for="address-office-input">
							<b-form-input
									id="address-office-input"
									name="address-office-input"
									v-model="address.office"
									placeholder="Введите номер офиса, если необходимо"
							></b-form-input>
						</b-form-group>
					</b-form>
				</b-modal>
				<br>Сгенерированный адрес: {{ this.branch.address }}
			</b-form-group>

			<b-form-group id="address-comment-input-group" label="Комментарий к адресу" v-if="haveAllPermissions"
										label-for="branch-address-comment-input">
				<b-form-input
						id="branch-address-comment-input"
						name="branch-address-comment-input"
						v-model="branch.address_comment"
						placeholder="Введите комментарий к адресу"
				></b-form-input>
			</b-form-group>

			<b-form-group id="address-sms-input-group" label="Адрес в смс" v-if="haveAllPermissions"
										label-for="branch-address-sms-input">
				<b-form-input
						id="branch-address-sms-input"
						name="branch-address-sms-input"
						v-model="branch.address_sms"
						placeholder="Введите адрес для смс"
				></b-form-input>
			</b-form-group>

			<b-form-group id="entity-input-group" label="Юр лицо докгена" v-if="haveAllPermissions"
										label-for="branch-entity-input">
				<b-form-select
						id="branch-entity-input"
						name="branch-entity-input"
						v-model="branch.entity"
						:options="entityOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="fdp-input-group" label="Кол-во дней до первого платежа" v-if="haveAllPermissions"
										label-for="branch-fdp-input">
				<b-form-input
						id="branch-fdp-input"
						name="branch-fdp-input"
						v-model="branch.first_day_pay"
						placeholder="Введите максимальное кол-во дней до первого платежа по умолчанию"
				></b-form-input>
			</b-form-group>

			<b-form-group id="map-code-input-group" v-if="haveAllPermissions" label="Код региона" label-for="branch-map-code-input">
				<b-form-input
						id="branch-map-code-input"
						name="branch-map-code-input"
						v-model="$v.branch.map_code.$model"
						:state="validateState('map_code')"
						aria-describedby="input-branch-map-code-live-feedback"
						placeholder="Введите код региона"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-map-code-live-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="map-code-input-group" label="Ссылка на виджет яндекс карт" v-if="haveAllPermissions" label-for="branch-map-yandex-widget">
				<b-form-input
						id="branch-map-yandex-widget-input"
						name="branch-map-yandex-widget-input"
						v-model="branch.yandex_map_url"
						:state="validateMapYandexWidget('yandex_map_url')"
						aria-describedby="input-branch-map-yandex-widget-live-feedback"
						placeholder="Введите ссылку на яндекс виджет карт"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-map-yandex-widget-live-feedback"
				>Ссылка должна начинаться с https://yandex.ru/map-widget/
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="row" v-if="haveAllPermissions">
				<div class="col-sm-6">
					<b-form-group id="image-input-view-group" label="Изображение" label-for="branch-image-input">
						<b-form-file
								id="branch-image-input"
								v-model="branch.image"
								accept=".jpg, .png, .gif, .jpeg, .webp"
								plain
						></b-form-file>
					</b-form-group>
				</div>

				<div class="col-sm-6">
					<b-form-group id="preview-input-view-group" label="Превью" label-for="branch-preview-input">
						<b-form-file
								id="branch-preview-input"
								v-model="branch.preview"
								accept=".jpg, .png, .gif, .jpeg, .webp"
								plain
						></b-form-file>
					</b-form-group>
				</div>
			</div>

			<div class="row" v-if="haveAllPermissions">
				<div class="col-sm-4">
					<b-form-group id="tariff1-input-group" label="Тариф один призыв" label-for="branch-tariff1-input">
						<b-form-input
								id="branch-tariff1-input"
								name="branch-tariff1-input"
								v-model="$v.branch.tariff1.$model"
								:state="validateState('tariff1')"
								aria-describedby="input-branch-tariff1-live-feedback"
								placeholder="Введите стоимость тарифа 'Один призыв'"
						></b-form-input>
						<b-form-invalid-feedback
								id="input-branch-tariff1-live-feedback"
						>Должно состоять только из цифр больше 0.
						</b-form-invalid-feedback>
					</b-form-group>
				</div>

				<div class="col-sm-4">
					<b-form-group id="tariff2-input-group" label="Тариф белый билет" label-for="branch-tariff2-input">
						<b-form-input
								id="branch-tariff2-input"
								name="branch-tariff2-input"
								v-model="$v.branch.tariff2.$model"
								:state="validateState('tariff2')"
								aria-describedby="input-branch-tariff2-live-feedback"
								placeholder="Введите стоимость тарифа 'белый билет'"
						></b-form-input>
						<b-form-invalid-feedback
								id="input-branch-tariff2-live-feedback"
						>Должно состоять только из цифр больше 0.
						</b-form-invalid-feedback>
					</b-form-group>
				</div>

				<div class="col-sm-4">
					<b-form-group id="tariff3-input-group" label="Тариф последний шанс" label-for="branch-tariff3-input">
						<b-form-input
								id="branch-tariff3-input"
								name="branch-tariff3-input"
								v-model="$v.branch.tariff3.$model"
								:state="validateState('tariff3')"
								aria-describedby="input-branch-tariff3-live-feedback"
								placeholder="Введите стоимость тарифа 'последний шанс'"
						></b-form-input>
						<b-form-invalid-feedback
								id="input-branch-tariff3-live-feedback"
						>Должно состоять только из цифр больше 0.
						</b-form-invalid-feedback>
					</b-form-group>
				</div>
			</div>

			<b-form-group id="time-offset-input-group" v-if="haveAllPermissions" label="Смещение времени UTC" label-for="branch-time-offset-input">
				<b-form-input
						id="branch-time-offset-input"
						name="branch-time-offset-input"
						v-model="$v.branch.time_offset.$model"
						:state="validateState('time_offset')"
						aria-describedby="input-branch-time-offset-live-feedback"
						placeholder="Введите смещение времени"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-branch-time-offset-live-feedback"
				>Смещение должно состоять из цифры от -12 до 14.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="entrance-photo-url-input-group" v-if="haveAllPermissions" label="Ссылка на фото входа в офис"
										label-for="branch-entrance-photo-url-input">
				<b-form-input
						id="branch-entrance-photo-url-input"
						name="branch-entrance-photo-url-input"
						v-model="branch.entrance_photo_url"
						placeholder="https://..."
				></b-form-input>
			</b-form-group>

			<b-form-group id="groups-input-group" v-if="haveAllPermissions || canUpdateBflCurator" label="Куратор бфл" label-for="user-groups-input">
				<b-form-tags id="user-groups-input" v-model="branchCurators" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onCuratorDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>{{ JSON.parse(tag).text }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
												menu-class="w-100">
							<template #button-content>
								Добавить
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск"
										label-for="group-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchCuratorsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchCurator"
											id="group-search-input"
											type="search"
											size="sm"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="curator in curatorAvailableOptions"
									:key="curator.id"
									@click="onCuratorAddClick({ option: curator, addTag: addTag })"
							>{{ curator.text }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="curatorAvailableOptions.length === 0">
								Не найдено
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<div
				v-if="haveAllPermissions || canUseHelpdeskChangeConsultation"
				style="border-top: 1px solid rgba(0, 0, 0, 0.05); border-bottom: 1px solid rgba(0, 0, 0, 0.05); margin-top: 1rem; margin-bottom: 1rem; padding-top: 1rem;"
			>
				<a name="consultations-anchor" ref="consultations-anchor" />
				<h5 class="form-header">
					Консультации
				</h5>

				<table class="w-100 mb-3 branch-consultations">
					<tr>
						<td></td>
						<td class="text-center pb-2">ПН</td>
						<td class="text-center pb-2">ВТ</td>
						<td class="text-center pb-2">СР</td>
						<td class="text-center pb-2">ЧТ</td>
						<td class="text-center pb-2">ПТ</td>
						<td class="text-center pb-2">СБ</td>
						<td class="text-center pb-2">ВС</td>
					</tr>
					<tr>
						<td>График работы</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-mon-input"
										name="branch-consultations-shift-start-mon-input"
										v-model="branch.settings.consultations_shift_start_mon"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-mon-input"
										name="branch-consultations-shift-start-mon-input"
										v-model="branch.settings.consultations_shift_end_mon"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-tue-input"
										name="branch-consultations-shift-start-tue-input"
										v-model="branch.settings.consultations_shift_start_tue"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-tue-input"
										name="branch-consultations-shift-start-tue-input"
										v-model="branch.settings.consultations_shift_end_tue"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-wed-input"
										name="branch-consultations-shift-start-wed-input"
										v-model="branch.settings.consultations_shift_start_wed"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-wed-input"
										name="branch-consultations-shift-start-wed-input"
										v-model="branch.settings.consultations_shift_end_wed"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-thu-input"
										name="branch-consultations-shift-start-thu-input"
										v-model="branch.settings.consultations_shift_start_thu"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-thu-input"
										name="branch-consultations-shift-start-thu-input"
										v-model="branch.settings.consultations_shift_end_thu"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-fri-input"
										name="branch-consultations-shift-start-fri-input"
										v-model="branch.settings.consultations_shift_start_fri"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-fri-input"
										name="branch-consultations-shift-start-fri-input"
										v-model="branch.settings.consultations_shift_end_fri"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-sat-input"
										name="branch-consultations-shift-start-sat-input"
										v-model="branch.settings.consultations_shift_start_sat"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-sat-input"
										name="branch-consultations-shift-start-sat-input"
										v-model="branch.settings.consultations_shift_end_sat"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
						<td class="px-1">
							<div class="row mx-0">
								<div class="col-sm-6 pl-0 pr-1">
									<b-form-input
										id="branch-consultations-shift-start-sun-input"
										name="branch-consultations-shift-start-sun-input"
										v-model="branch.settings.consultations_shift_start_sun"
										placeholder="09:00"
									></b-form-input>
								</div>
								<div class="col-sm-6 pl-1 pr-0">
									<b-form-input
										id="branch-consultations-shift-start-sun-input"
										name="branch-consultations-shift-start-sun-input"
										v-model="branch.settings.consultations_shift_end_sun"
										placeholder="23:00"
									></b-form-input>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>Количество записей на одно время МПК</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-mon-mpk-input"
								name="branch-consultations-on-specialist-mon-mpk-input"
								v-model="branch.settings.consultations_on_specialist_mon_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-tue-mpk-input"
								name="branch-consultations-on-specialist-tue-mpk-input"
								v-model="branch.settings.consultations_on_specialist_tue_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-wed-mpk-input"
								name="branch-consultations-on-specialist-wed-mpk-input"
								v-model="branch.settings.consultations_on_specialist_wed_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-thu-mpk-input"
								name="branch-consultations-on-specialist-thu-mpk-input"
								v-model="branch.settings.consultations_on_specialist_thu_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-fri-mpk-input"
								name="branch-consultations-on-specialist-fri-mpk-input"
								v-model="branch.settings.consultations_on_specialist_fri_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-sat-mpk-input"
								name="branch-consultations-on-specialist-sat-mpk-input"
								v-model="branch.settings.consultations_on_specialist_sat_mpk"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-sun-mpk-input"
								name="branch-consultations-on-specialist-sun-mpk-input"
								v-model="branch.settings.consultations_on_specialist_sun_mpk"
								placeholder="3"
							></b-form-input>
						</td>
					</tr>
					<tr>
						<td>Количество записей на одно время БФЛ</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-mon-bfl-input"
								name="branch-consultations-on-specialist-mon-bfl-input"
								v-model="branch.settings.consultations_on_specialist_mon_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-tue-bfl-input"
								name="branch-consultations-on-specialist-tue-bfl-input"
								v-model="branch.settings.consultations_on_specialist_tue_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-wed-bfl-input"
								name="branch-consultations-on-specialist-wed-bfl-input"
								v-model="branch.settings.consultations_on_specialist_wed_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-thu-bfl-input"
								name="branch-consultations-on-specialist-thu-bfl-input"
								v-model="branch.settings.consultations_on_specialist_thu_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-fri-bfl-input"
								name="branch-consultations-on-specialist-fri-bfl-input"
								v-model="branch.settings.consultations_on_specialist_fri_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-sat-bfl-input"
								name="branch-consultations-on-specialist-sat-bfl-input"
								v-model="branch.settings.consultations_on_specialist_sat_bfl"
								placeholder="3"
							></b-form-input>
						</td>
						<td class="px-1">
							<b-form-input
								id="branch-consultations-on-specialist-sun-bfl-input"
								name="branch-consultations-on-specialist-sun-bfl-input"
								v-model="branch.settings.consultations_on_specialist_sun_bfl"
								placeholder="3"
							></b-form-input>
						</td>
					</tr>
				</table>

				<b-form-group
					id="branch-consultations-office"
					label="Консультации в офисе" 
					label-for="branch-consultations-office-input"
				>
					<b-form-select
						id="branch-consultations-office-input"
						name="branch-consultations-office-input"
						v-model="branch.settings.consultations_office"
						:options="yesNoOptions"
					></b-form-select>
				</b-form-group>

				<b-form-group
					id="branch-consultations-online"
					label="Консультации онлайн" 
					label-for="branch-consultations-online-input"
				>
					<b-form-select
						id="branch-consultations-online-input"
						name="branch-consultations-online-input"
						v-model="branch.settings.consultations_online"
						:options="yesNoOptions"
					></b-form-select>
				</b-form-group>

				<b-form-group
					id="branch-consultations-same-day"
					label="Консультации день-в-день" 
					label-for="branch-consultations-input"
				>
					<b-form-select
						id="branch-consultations-input"
						name="branch-consultations-input"
						v-model="branch.settings.consultations_same_day"
						:options="yesNoOptions"
						:disabled="!canUseHelpdeskChangeConsultation"
					></b-form-select>
				</b-form-group>


				<b-form-group
					id="branch-consultations-on-we"
					label="Консультации в сб. и вс." 
					label-for="branch-consultations-on-we-input"
				>
					<b-form-select
						id="branch-consultations-on-we-input"
						name="branch-consultations-on-we-input"
						v-model="branch.settings.consultations_on_weekends"
						:options="yesNoOptions"
					></b-form-select>
				</b-form-group>

				<div class="row">
					<div class="col-sm-6">
						<b-form-group
							id="branch-consultations-date-limit-mpk"
							label="Ограничение записи на консультацию (дней) МПК"
							label-for="branch-consultations-date-limit-mpk-input"
						>
							<b-form-input
								id="branch-consultations-date-limit-mpk-input"
								name="branch-consultations-date-limit-mpk-input"
								v-model="branch.settings.consultations_date_limit_mpk"
								aria-describedby="input-branch-consultations-date-limit-mpk-live-feedback"
								placeholder="Введите значение"
							></b-form-input>
							<b-form-invalid-feedback
								id="input-branch-consultations-date-limit-mpk-live-feedback"
							>Обязательное поле.
							</b-form-invalid-feedback>
						</b-form-group>
					</div>
					<div class="col-sm-6">
						<b-form-group
							id="branch-consultations-date-limit-bfl"
							label="Ограничение записи на консультацию (дней) БФЛ"
							label-for="branch-consultations-date-limit-bfl-input"
						>
							<b-form-input
								id="branch-consultations-date-limit-bfl-input"
								name="branch-consultations-date-limit-bfl-input"
								v-model="branch.settings.consultations_date_limit_bfl"
								aria-describedby="input-branch-consultations-date-limit-bfl-live-feedback"
								placeholder="Введите значение"
							></b-form-input>
							<b-form-invalid-feedback
								id="input-branch-consultations-date-limit-bfl-live-feedback"
							>Обязательное поле.
							</b-form-invalid-feedback>
						</b-form-group>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-6">
						<b-form-group
							id="branch-consultations-timeslot-mpk"
							label="Длительность консультации (слот времени) МПК"
							label-for="branch-consultations-timeslot-mpk-input"
						>
							<b-form-select
								id="branch-consultations-timeslot-mpk-input"
								name="branch-consultations-timeslot-mpk-input"
								v-model="branch.settings.consultations_timeslot_mpk"
								:options="consultationsTimeslots"
								aria-describedby="input-branch-consultations-timeslot-mpk-live-feedback"
							></b-form-select>
							<b-form-invalid-feedback
								id="input-branch-consultations-timeslot-mpk-live-feedback"
							>Обязательное поле.
							</b-form-invalid-feedback>
						</b-form-group>
					</div>
					<div class="col-sm-6">
						<b-form-group
							id="branch-consultations-timeslot-bfl"
							label="Длительность консультации (слот времени) БФЛ"
							label-for="branch-consultations-timeslot-bfl-input"
						>
							<b-form-select
								id="branch-consultations-timeslot-bfl-input"
								name="branch-consultations-timeslot-bfl-input"
								v-model="branch.settings.consultations_timeslot_bfl"
								:options="consultationsTimeslots"
								aria-describedby="input-branch-consultations-timeslot-bfl-live-feedback"
							></b-form-select>
							<b-form-invalid-feedback
								id="input-branch-consultations-timeslot-bfl-live-feedback"
							>Обязательное поле.
							</b-form-invalid-feedback>
						</b-form-group>
					</div>
				</div>

				<b-form-group
					id="branch-office-comment"
					label="Комментарий по работе офиса"
					label-for="branch-office-comment-input"
					v-if="haveAllPermissions || canUseHelpdeskChangeConsultation"
				>
					<vue-editor
						id="branch-office-comment-input"
						v-model="branch.settings.office_comment"
					></vue-editor>
				</b-form-group>

				<b-form-group
					id="branch-manager-tg"
					label="Telegram менеджера"
					label-for="branch-manager-tg-input"
					v-if="haveAllPermissions || canUseHelpdeskChangeConsultation"
				>
					<b-form-textarea
						id="branch-manager-tg-input"
						v-model="branch.settings.manager_tg"
						rows="2"
					></b-form-textarea>
				</b-form-group>
			</div>
			
			<b-form-checkbox
				id="branch-show_on_sites-input"
				v-model="branch.show_on_sites"
				value="true"
				unchecked-value="false"
				v-if="haveAllPermissions"
			>
				Отображать филиал на сайтах
			</b-form-checkbox>

			<b-form-checkbox
				id="city-status-coven-input"
				v-model="branch.status_coven"
				name="city-status-coven-input"
				value="true"
				unchecked-value="false"
				v-if="haveAllPermissions"
			>
				Использовать филиал в coven
			</b-form-checkbox>
			

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" @click="submit" variant="primary" name="button">Сохранить</b-button>
				<b-button class="btn btn-grey" @click="backToBranches">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength, minValue, requiredIf, integer, maxValue} from 'vuelidate/lib/validators';
import { VueEditor } from 'vue2-editor';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import {routeNames} from '@/router/constants';
import {
	helpdeskBranchStatusEnum,
	helpdeskBranchConsultationsTimeslotsEnum
} from '@/store/helpdesk/branches/constants';
import {permissionNames} from '@/store/passport/auth/constants';

const rusNames = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /^[а-яА-Я]+(?:[\s-][а-яА-Я]+)*$/.test(value);
};

export default {
	name: 'HelpDeskBranchForm',
	mixins: [validationMixin],
	components: {
		VueEditor,
	},
	validations: {
		branch: {
			amo_id: {
				integer,
				minValue: minValue(1)
			},
			bfl_amo_id: {
				integer,
				minValue: minValue(1)
			},
			amo_group_id: {
				integer,
				minValue: minValue(0)
			},
			amo_group_name: {
				minLength: minLength(2),
				rusNames
			},
			old_city_id: {
				integer,
				minValue: minValue(1)
			},
			name: {
				required,
				minLength: minLength(2),
				rusNames
			},
			status: {
				required
			},
			city_id: {
				integer,
				minValue: minValue(1)
			},
			map_code: {
				required,
				maxLength: maxLength(11)
			},
			tariff1: {
				required: requiredIf(function (branch) {
					return branch.tariff2 || branch.tariff3;
				}),
				minValue: minValue(1)
			},
			tariff2: {
				required: requiredIf(function (branch) {
					return branch.tariff1 || branch.tariff3;
				}),
				minValue: minValue(1)
			},
			tariff3: {
				required: requiredIf(function (branch) {
					return branch.tariff1 || branch.tariff2;
				}),
				minValue: minValue(1)
			},
			time_offset: {
				required,
				minValue: minValue(-12),
				maxValue: maxValue(14),
			},
		},
		address: {
			city: {
				required
			},
			street: {
				street: {
					required
				},
				cut: {
					required
				}
			},
			house: {
				required
			}
		}
	},
	data() {
		return {
			branch: {
				old_city_id: null,
				bfl_curator: [],
				project: [],
				amo_id: null,
				bfl_amo_id: null,
				amo_group_id: null,
				amo_group_name: null,
				name: '',
				status: null,
				city_id: null,
				date_open: null,
				work_hours: null,
				address: null,
				address_comment: null,
				address_sms: null,
				entity: 'lt',
				first_day_pay: 45,
				map_code: '',
				show_on_sites: false,
				status_coven: false,
				time_offset: 3,
				image: null,
				preview: null,
				tariff1: null,
				tariff2: null,
				tariff3: null,
				yandex_map_url: null,
				latitude: null,
				longitude: null,
				entrance_photo_url: null,
				settings: {
					consultations_office: true,
					consultations_online: true,
					consultations_same_day: true,
					consultations_on_weekends: true,
					consultations_timeslot_bfl: 30,
					consultations_timeslot_mpk: 30,
					consultations_date_limit_bfl: 6,
					consultations_date_limit_mpk: 6,
					consultations_shift_start_mon: '09:00',
					consultations_shift_end_mon: '23:00',
					consultations_on_specialist_mon_bfl: 3,
					consultations_on_specialist_mon_mpk: 3,
					consultations_shift_start_tue: '09:00',
					consultations_shift_end_tue: '23:00',
					consultations_on_specialist_tue_bfl: 3,
					consultations_on_specialist_tue_mpk: 3,
					consultations_shift_start_wed: '09:00',
					consultations_shift_end_wed: '23:00',
					consultations_on_specialist_wed_bfl: 3,
					consultations_on_specialist_wed_mpk: 3,
					consultations_shift_start_thu: '09:00',
					consultations_shift_end_thu: '23:00',
					consultations_on_specialist_thu_bfl: 3,
					consultations_on_specialist_thu_mpk: 3,
					consultations_shift_start_fri: '09:00',
					consultations_shift_end_fri: '23:00',
					consultations_on_specialist_fri_bfl: 3,
					consultations_on_specialist_fri_mpk: 3,
					consultations_shift_start_sat: '09:00',
					consultations_shift_end_sat: '23:00',
					consultations_on_specialist_sat_bfl: 3,
					consultations_on_specialist_sat_mpk: 3,
					consultations_shift_start_sun: '09:00',
					consultations_shift_end_sun: '23:00',
					consultations_on_specialist_sun_bfl: 3,
					consultations_on_specialist_sun_mpk: 3,
					office_comment: '',
					manager_tg: '',
				},
			},
			address: {
				city: null,
				street: {
					cut: 'ул.',
					street: null
				},
				house: null,
				corpus: null,
				floor: null,
				office: null
			},
			statuses: [
				{value: null, text: 'Выберите статус...'}
			],
			addressCutOptions: [
				{value: 'ул.', text: 'Улица'},
				{value: 'пр-кт.', text: 'Проспект'},
				{value: 'б-р', text: 'Бульвар'},
				{value: 'пер.', text: 'Переулок'}
			],
			entityOptions: [
				{value: 'mpk', text: 'МПК'},
				{value: 'lt', text: 'LegalTech'},
			],
			yesNoOptions: [
				{value: true, text: 'Да'},
				{value: false, text: 'Нет'},
			],
			projectOptions: [
				{value: 'mpk', text: 'Призыванет', disabled: false},
				{value: 'bfl', text: 'Кредитанет', disabled: false},
			],
			bflCuratorOptions: [],
			branchCurators: [],
			searchCurator: '',

			consultationsTimeslots: [],
			d: Date.now(),
			hash: this.$route.hash,
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_UPDATE_BRANCH', 'HELPDESK_GET_BRANCH_FROM_API', 'HELPDESK_GET_CITIES_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY', 'HELPDESK_GET_BFL_USERS', 'HELPDESK_GET_NEW_CITIES_FROM_API'
		]),

		onCuratorAddClick({option, addTag}) {
			// если группа уже добавлена к пользователю - возврат
			if (this.branch.bfl_curator.findIndex(x => x === option.value) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Куратор уже добавлен'
				});
				return;
			}
			this.branch.bfl_curator.push(option.value);

			let id = this.bflCuratorOptions.findIndex(x => x.value === option.value);
			if (id > -1) {
				this.bflCuratorOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchCurator = '';
		},

		onCuratorDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let curatorToRemove = JSON.parse(option);

			let idAddedGroup = this.branch.bfl_curator.findIndex(x => x === curatorToRemove.value);
			if (idAddedGroup > -1) {
				this.branch.bfl_curator.splice(idAddedGroup, 1);
			}
			this.bflCuratorOptions.push(curatorToRemove);
			this.bflCuratorOptions.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			removeTag(option);
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.branch[name];
			return $dirty ? !$error : null;
		},

		validateSettings(name) {
			const {$dirty, $error} = this.$v.branch.settings[name];
			return $dirty ? !$error : null;
		},

		validateMapYandexWidget(name) {
			const value = this.branch[name];
			if (value) {
				if (!(value.includes('https://yandex.ru/map-widget/'))) {
					return false;
				}
			}
			return true;
		},

		validateAddressState(name) {
			const {$dirty, $error} = this.$v.address[name];
			return $dirty ? !$error : null;
		},

		backToBranchesTable() {
			this.$router.push({name: routeNames.helpdesk.branches.read, query: this.$route.query});
		},

		backToBranchesConsultationsTable() {
			this.$router.push({name: routeNames.helpdesk.branches.consultations, query: this.$route.query});
		},

		backToBranches() {
			if (this.hash.replace('#', '') === 'consultations-anchor') {
				this.backToBranchesConsultationsTable();
			} else {
				this.backToBranchesTable();
			}
		},

		getPreview() {
			return `${VUE_APP_HELPDESK_API_URL}/api/v1/branch/${this.$route.params.branch_id}/preview?timestamp=${this.d}`;
		},
		getImage() {
			return `${VUE_APP_HELPDESK_API_URL}/api/v1/branch/${this.$route.params.branch_id}/image?timestamp=${this.d}`;
		},
		imageLoadError() {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error', title: 'Ошибка',
				message: 'Произошла ошибка при получении изображения филиала'
			});
		},

		generateAddress() {
			if (this.branch.city_id === null) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Для генерации адреса необходимо выбрать город'
				});
				return;
			}
			this.branch.address = `г. ${this.address.city}, ${this.address.street.cut} ${this.address.street.street}`;
			this.branch.address += `, д. ${this.address.house}`;
			if (this.address.corpus !== null && this.address.corpus !== '') {
				this.branch.address += `, к. ${this.address.corpus}`;
			}
			if (this.address.floor !== null && this.address.floor !== '') {
				this.branch.address += `, этаж ${this.address.floor}`;
			}
			if (this.address.office !== null && this.address.office !== '') {
				this.branch.address += `, офис ${this.address.office}`;
			}
		},

		findAddressCity() {
			let city = this.helpdeskNewCities.find(x => x.id === this.branch.city_id);
			if (city === undefined) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Ошибка поиска названия выбранного города'
				});
			}
			this.address.city = city.main_name;
		},

		parseAddress(address) {
			if (!address) {
				return;
			}
			let result = address.match(/(ул[.]|пр-кт[.]|б-р|пер[.])s*([0-9а-яА-ЯёЁ\-\s]+)/);
			if (result !== null) {
				this.address.street.cut = result[1];
				this.address.street.street = result[2].slice(1);
			}

			result = address.match(/(д)[.]s*([0-9а-яА-ЯёЁ/\s]+)/);
			if (result !== null) {
				this.address.house = result[2].slice(1);
			}

			result = address.match(/(к)[.]s*[0-9а-яА-ЯёЁ\s]+/);
			if (result !== null) {
				this.address.corpus = result[0].slice(3);
			}

			result = address.match(/(этаж)s*[0-9\s]+/);
			if (result !== null) {
				this.address.floor = result[0].slice(5);
			}

			result = address.match(/(офис)s*[0-9а-яА-ЯёЁ./\-\s]+/);
			if (result !== null) {
				this.address.office = result[0].slice(5);
			}
		},

		async submit() {
			this.$v.branch.$touch();
			if (this.$v.branch.$anyError) {
				return;
			}

			await this.HELPDESK_UPDATE_BRANCH({id: this.$route.params.branch_id, branch: this.branch}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Филиал успешно обновлён'});

				if (this.hash.replace('#', '') === 'consultations-anchor') {
					this.backToBranchesConsultationsTable();
				} else {
					this.backToBranchesTable();
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении филиала'
				});
			});
		},

		scrollToAnchorPoint(refName) {
			const el = this.$refs[refName];
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth'
				});
			}
		},

		getUserWorkInOffices() {
			return this.attribute('user_working_in_offices');
		}
	},
	computed: {
		...mapGetters([
			'helpdeskBranch', 'helpdeskCities', 'permissionAvailable', 'helpdeskNewCities', 'helpdeskBflUsers', 'attribute',
		]),
		haveAllPermissions() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCHES_API);
		},
		canUpdateBflCurator() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL);
		},
		canUpdateBflProject() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL);
		},
		canUseHelpdeskChangeConsultation() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_CHANGE_CONSULTATION);
		},
		curatorCriteria() {
			return this.searchCurator.trim().toLowerCase();
		},
		curatorAvailableOptions() {
			const criteria = this.curatorCriteria;
			if (criteria) {
				return this.bflCuratorOptions.filter((opt) => opt.text.toLowerCase().indexOf(criteria) > -1);
			}
			return this.bflCuratorOptions;
		},
		searchCuratorsDesc() {
			if (this.curatorCriteria && this.curatorAvailableOptions.length === 0) {
				return 'Нет кураторов с заданным именем';
			}
			return '';
		},
		newCityOptions() {
			const a = [];
			for (let city of this.helpdeskNewCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
		oldCityOptions() {
			const a = [];
			for (let city of this.helpdeskCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
	},
	async mounted() {
		if (this.canUpdateBflProject) {
			this.projectOptions[0].disabled = true;
		}
		for (let e in helpdeskBranchStatusEnum) {
			this.statuses.push(helpdeskBranchStatusEnum[e]);
		}
		for (let e in helpdeskBranchConsultationsTimeslotsEnum) {
			this.consultationsTimeslots.push(
				helpdeskBranchConsultationsTimeslotsEnum[e]
			);
		}
		if (this.$route.params.branch_id !== '0') {
			let attributeBranches = this.getUserWorkInOffices();
			if (attributeBranches && attributeBranches.length > 0) {
				const branchIds = attributeBranches.replace(' ', '').split(',').map(Number);
				if (!branchIds.includes(Number(this.$route.params.branch_id))) {
					await this.$router.push('/404');
				}
			}

			await this.HELPDESK_GET_BRANCH_FROM_API(this.$route.params.branch_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении филиала'
				});
			});

			this.branch = this.helpdeskBranch;
			this.branch.image = null;
			this.branch.preview = null;
			this.branch.date_open = new Date(this.branch.date_open);
		}
		if (this.branch.city_id !== null) {
			this.findAddressCity();
		}
		this.parseAddress(this.branch.address);
		await Promise.all([await this.HELPDESK_GET_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка старых городов'
			});
		}), this.HELPDESK_GET_NEW_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка новых городов'
			});
		})]);

    if (this.haveAllPermissions || this.canUpdateBflCurator) {
      await this.HELPDESK_GET_BFL_USERS().catch(() => {
        this.ADD_NOTIFICATION_TO_QUERY({
          type: 'error',
          title: 'Ошибка',
          message: 'Произошла ошибка при получении списка пользователей амо бфл'
        });
      });
      for (let user of this.helpdeskBflUsers) {
        this.bflCuratorOptions.push({value: user.id, text: user.name});
      }
      this.bflCuratorOptions.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
      for (let i = 0; i < this.branch.bfl_curator.length; i++) {
        const curator = this.bflCuratorOptions.find((v) => v.value === this.branch.bfl_curator[i]);
        if (curator) {
          this.branchCurators.push(curator);
        }
      }
    }

		this.$nextTick(() => {
			if (this.hash) {
				const refName = this.hash.replace('#', '');
				this.scrollToAnchorPoint(refName);
			}
			this.$v.$reset();
		});
	},
};
</script>

<style scoped>
.image {
	max-width: 100%;
	max-height: 304px;
}
.branch-consultations td {
	border: 1px solid rgba(83, 101, 140, 0.33);
}
</style>
