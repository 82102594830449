<template>
	<div class="element-box">
		<h5 class="form-header">
			Расписание работы сотрудников {{ filterDate }}
			<template v-if="filterOffice">
				/ {{ filterOffice }}
			</template>
		</h5>
		<div class="table-responsive">

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
							id="filters-modal"
							title="Фильтрация пользователей"
							ok-title="Применить"
							cancel-title="Отмена"
							v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="users-id-filter-group" label="ID" label-for="users-id-filter">
									<b-form-input
										id="users-id-filter"
										v-model="filters.id"
										placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-login-filter-group" label="Логин"
															label-for="users-login-filter">
									<b-form-input
										id="users-login-filter"
										v-model="filters.login"
										placeholder="Введите искомый login"
									></b-form-input>
								</b-form-group>
								<b-form-group
									id="users-name-filter-group"
									label="ФИО"
									label-for="users-name-filter"
								>
									<b-form-input
										id="users-name-filter"
										v-model="filters.name"
										placeholder="Введите ФИО"
									></b-form-input>
								</b-form-group>

								<b-form-group
									label="Вывод пользователей"
									label-for="users-disabled-filter"
								>
									<b-form-select
											v-model="filters.disabled"
											:options="disabledSelectOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group label="Офис" label-for="branches-office_id-filter">
									<b-form-select
										id="branches-office_id-filter"
										v-model="filters.officeId"
										multiple
										:options="officesOptions"
									></b-form-select>
								</b-form-group>
								<div class="row">
									<div class="col-sm-6">
										<b-form-group label="Месяц" label-for="users-month-filter">
											<b-form-select
												v-model="filters.month"
												:options="months"
											></b-form-select>
										</b-form-group>
									</div>
									<div class="col-sm-6">
										<b-form-group label="Год" label-for="users-year-filter">
											<b-form-select
												v-model="filters.year"
												:options="years"
											></b-form-select>
										</b-form-group>
									</div>
								</div>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
								id="passport-users-get-limit"
								name="passport-users-get-limit"
								v-model="filters.limit"
								:options="limits"
								class="form-control form-control-sm rounded bright"
								@change="getUsers()"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<div class="calendar-container">
				<table class="table table-lightborder calendar-table">
					<tbody>
						<tr class="thead">
							<td>
								<div>
									Имя
								</div>
							</td>
							<td>
								<div>
									Офис
								</div>
							</td>
							<td>
								<div>
									Подр.
								</div>
							</td>
							<td v-for="(c, i) in generateCalendar" :key="i">
								<div>
									{{ c.name }}
								</div>
							</td>
						</tr>
						<tr v-for="user in passportUsers" :key="user.id">
							<td>
								<div>
									<router-link
										title="Редактировать"
										:to="{ name: routeNames.passport.schedule.update, params: { user_id: user.id }, query: filters}"
									>
										{{ user.name }}
									</router-link>
								</div>
							</td>
							<td>
								<div>
									{{ getUserOffice(user) }}
								</div>
							</td>
							<td>
								<div>
									{{ getUserSubdivision(user) }}
								</div>
							</td>
							<td v-for="(c, i) in generateCalendar" :key="i">
								<div>
									{{ getScheduleItem(user.id, c.date) }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && passportUsers.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ScheduleTable',
	data() {
		const d = new Date();

		const years = [];
		for (let i = 0; i < 3; i += 1) {
			years.push(d.getFullYear() + i);
		}

		return {
			filters: {
				id: null,
				ids: null,
				login: null,
				name: null,
				disabled: 0,
				officeId: [],
				month: d.getMonth(),
				year: d.getFullYear(),
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Пользователей на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],

			disabledSelectOptions: [
				{value: undefined, text: 'Все пользователи'},
				{value: 1, text: 'Только отключённые'},
				{value: 0, text: 'Только активные'},
			],

			months: [
				{value: 0, text: 'Январь'},
				{value: 1, text: 'Ферваль'},
				{value: 2, text: 'Март'},
				{value: 3, text: 'Апрель'},
				{value: 4, text: 'Май'},
				{value: 5, text: 'Июнь'},
				{value: 6, text: 'Июль'},
				{value: 7, text: 'Август'},
				{value: 8, text: 'Сентябрь'},
				{value: 9, text: 'Октябрь'},
				{value: 10, text: 'Ноябрь'},
				{value: 11, text: 'Декабрь'},
			],
			years,

			canGetNext: true,
			canGetPrev: false,
			calendarMonth: d.getMonth(),
			calendarYear: d.getFullYear(),
			selectedOfficeId: [],
			routeNames,
		};
	},
	computed: {
		...mapGetters([
			'passportUsers',
			'helpdeskBranches',
			'helpdeskAvailableBranchesWithCityId',
			'staffSchedule',
			'staffOffices',
		]),
		generateCalendar() {
			const d = new Date(this.calendarYear, this.calendarMonth, 1);
			const maxDays = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();

			const days = [];

			for (let i = 1; i <= maxDays; i += 1) {
				const m = d.getMonth() + 1;
				days.push({
					date: d.getFullYear() + '-' + (m < 10 ? '0'+m : m) + '-' + (i < 10 ? '0'+i : i),
					name: (i < 10 ? '0'+i : i) + '.' + (m < 10 ? '0'+m : m),
				});
			}

			return days;
		},
		filterDate() {
			const m = this.months.find(month => month.value === this.calendarMonth);
			if (m) {
				return `${m.text} ${this.calendarYear}`;
			}

			return '';
		},
		filterOffice() {
			if (this.selectedOfficeId && this.selectedOfficeId.length) {
				const offices = [];

				for (let i = 0; i < this.selectedOfficeId.length; i += 1) {
					const office = this.officesOptions.find(o => o.value === this.selectedOfficeId[i]) || null;
					if (office) {
						offices.push(office.text);
					}
				}

				if (offices.length) {
					return offices.join(', ');
				}
			}

			return null;
		},
		officesOptions() {
			const a = [];
			for (let office of this.helpdeskAvailableBranchesWithCityId) {
				a.push({value: office.id, text: office.name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			return a;
		},
	},
	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_BRANCHES_FROM_API',
			'HELPDESK_GET_STAFF_SCHEDULE_FROM_API',
			'HELPDESK_GET_STAFF_OFFICES_FROM_API',
		]),

		async applyFilters() {
			this.calendarMonth = this.filters.month;
			this.calendarYear = this.filters.year;

			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;

			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getSchedule(ids);
				await this.getStaffOffices(ids);
			}
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getUsers();

				const ids = [];

				for (let i = 0; i < this.passportUsers.length; i += 1) {
					ids.push(this.passportUsers[i].id);
				}

				if (ids.length > 0) {
					await this.getSchedule(ids);
					await this.getStaffOffices(ids);
				}

				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getSchedule(ids);
				await this.getStaffOffices(ids);
			}

			if (this.passportUsers.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getUsers() {
			const filters = JSON.parse(JSON.stringify(this.filters));

			if (this.filters.officeId && this.filters.officeId.length) {
				const offices = await this.getUsersByOfficeId(this.filters.officeId.join(','));

				if (offices && offices.length) {
					const userIds = [];

					for (let i = 0; i < offices.length; i += 1) {
						userIds.push(offices[i].userId);
					}

					filters.ids = userIds.join(',');
				}

				filters.officeId = null;
			}

			this.selectedOfficeId = this.filters.officeId;

			await this.GET_USERS_FROM_API(filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пользователей'
				});
			});
			if (this.filters.offset > 0) {
				this.canGetPrev = true;
			}
		},

		async getSchedule(users) {
			const currentMonth = this.filters.month + 1;
			const dateParam = this.filters.year.toString() + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth);

			await this.HELPDESK_GET_STAFF_SCHEDULE_FROM_API({userId: users.join(','), fromMonth: dateParam, toMonth: dateParam}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка расписаний'
				});
			});
		},

		async getStaffOffices(users) {
			await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({userId: users.join(',')}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});
		},

		async getUsersByOfficeId(officeId) {
			const res = await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({officeId: officeId}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});

			return res;
		},

		getScheduleItem(userId, date) {
			for (let i = 0; i < this.staffSchedule.length; i += 1) {
				if (this.staffSchedule[i].userId === userId && this.staffSchedule[i].date === date) {
					if (this.staffSchedule[i].absenceReason !== '') {
						return this.staffSchedule[i].absenceReason;
					} else {
						return this.staffSchedule[i].startTime + ' - ' + this.staffSchedule[i].endTime;
					}
				}
			}

			return '';
		},

		getUserOffice(user) {
			const offices = [];
			const result = this.staffOffices.find(o => o.userId === user.id) || null;

			if (result) {
				if (result.offices.length > 0) {
					for (let i = 0; i < result.offices.length; i += 1) {
						for (let j = 0; j < this.helpdeskBranches.length; j += 1) {
							if (this.helpdeskBranches[j].id === result.offices[i]) {
								offices.push(this.helpdeskBranches[j].name);
							}
						}
					}
				}
			}

			return offices.join(', ');
		},

		getUserSubdivision(user) {
			if (user.userAttributes && user.userAttributes.length) {
				for (let i = 0; i < user.userAttributes.length; i += 1) {
					if (user.userAttributes[i].code === 'subdivision') {
						return user.userAttributes[i].value;
					}
				}
			}

			return '';
		}
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				if (q === 'month' || q === 'year') {
					this.filters[q] = parseInt(this.$route.query[q]);
				} else {
					this.filters[q] = this.$route.query[q];
				}
			}
		}

		this.calendarMonth = this.filters.month;
		this.calendarYear = this.filters.year;

		await this.getUsers();

		await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});

		const ids = [];

		for (let i = 0; i < this.passportUsers.length; i += 1) {
			ids.push(this.passportUsers[i].id);
		}

		if (ids.length > 0) {
			await this.getSchedule(ids);
			await this.getStaffOffices(ids);
		}
	},
};
</script>

<style>
.calendar-container {
	position: relative;
	width: 100%;
	max-width: 100%;
	overflow: auto;
}
.calendar-table {
	th, td {
		flex: 1 0 10em;
		padding: 0 !important;
	}

	th:nth-child(1), td:nth-child(1), th:nth-child(2), td:nth-child(2) {
		flex: 1 0 20em;
	}

	td > div {
		position: relative;
		padding: 0.75rem 0;
	}

	tr.thead > td {
		text-transform: uppercase;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #999;
	}

	tr.thead > td > div {
		padding: 0 0 0.25rem 0;
	}

	thead, tbody {
		display: flex;
		flex-direction: column;
	}

	tr {
		display: flex;
		flex: 1 0;
	}
}
</style>
