<template>
	<div class="element-box">
		<h5 class="form-header">
			Офисы работы сотрудника {{ user.name }}
		</h5>
		<div class="form-desc">
			Измените необходимые данные и нажмите сохранить
		</div>

		<div>
			<b-form-group id="branches-input-group" label="Офисы" label-for="user-branches-input">
				<b-form-tags id="user-branches-input" v-model="userOffices" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onOfficeDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									{{ JSON.parse(tag).name }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление офисов
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск офисов"
										label-for="branch-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										description=""
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchOffices"
											id="branch-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название офиса"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="office in officesAvailableOptions"
									:key="office.id"
									@click="onOfficeAddClick({ option: office, addTag: addTag })"
							>{{ office.name }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="officesAvailableOptions.length === 0">
								По вашему запросу ничего не найдено
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>
		</div>
		<div class="form-buttons-w">
			<b-button
				class="btn btn-primary"
				type="button"
				variant="primary"
				name="button"
				@click="saveOffices()"
			>
				Сохранить
			</b-button>
			<b-button class="btn btn-grey" @click="backToOfficesTable">Назад</b-button>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'OfficesUpdateForm',
	data() {
		return {
			filters: {},
			user: {
				id: '',
				name: '',
			},
			userOffices: [],
			officesOptions: [],
			searchOffices: '',
		};
	},

	computed: {
		...mapGetters([
			'passportUsers',
			'staffOffices',
			'helpdeskBranches', 
		]),
		officesCriteria() {
			return this.searchOffices.trim().toLowerCase();
		},
		officesAvailableOptions() {
			const criteria = this.officesCriteria;
			if (criteria) {
				return this.officesOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.officesOptions;
		},
	},

	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'HELPDESK_GET_BRANCHES_FROM_API',
			'HELPDESK_GET_STAFF_OFFICES_FROM_API',
			'HELPDESK_SAVE_STAFF_OFFICES',
			'ADD_NOTIFICATION_TO_QUERY',
		]),

		backToOfficesTable() {
			this.$router.push({name: routeNames.passport.offices.read, query: this.filters});
		},

		async getOffices() {
			await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({userId: this.user.id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудника'
				});
			});
		},

		async saveOffices() {
			const officesData = {
				userId: this.user.id,
				offices: [],
			};

			if (this.userOffices.length) {
				this.userOffices.forEach(function callback(userOffice) {
					let office = userOffice;
					if (typeof office != 'object') {
						office = JSON.parse(office);
					}
					officesData.offices.push(office.id);
				});
			}

			await this.HELPDESK_SAVE_STAFF_OFFICES(officesData).then(async () => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Успешно'});
				await this.getOffices();

				let userOffices = [];
				this.userOffices = [];
				this.officesOptions = [];

				if (this.staffOffices && this.staffOffices.length) {
					userOffices = this.staffOffices[0].offices;
				}

				for (let i = 0; i < this.helpdeskBranches.length; i++) {
					if (userOffices.includes(this.helpdeskBranches[i].id)) {
						this.userOffices.push({
							id: this.helpdeskBranches[i].id,
							name: this.helpdeskBranches[i].name,
						});
					} else {
						this.officesOptions.push({
							id: this.helpdeskBranches[i].id,
							name: this.helpdeskBranches[i].name,
						});
					}
				}
			}).catch((e) => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка'
				});
			});
		},

		onOfficeAddClick({option, addTag}) {
			if (this.userOffices.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Офис уже добавлен'
				});
				return;
			}
			this.userOffices.push({id: option.id, name: option.name});

			let id = this.officesOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.officesOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchOffices = '';
		},

		onOfficeDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let officesToRemove = JSON.parse(option);

			let isAddedOffice = this.userOffices.findIndex(x => x.id === officesToRemove.id);
			if (isAddedOffice > -1) {
				this.userOffices.splice(isAddedOffice, 1);
			}
			this.officesOptions.push(officesToRemove);
			this.officesOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}

		if (this.$route.params.user_id !== '0') {
			await this.GET_USERS_FROM_API({id: this.$route.params.user_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении пользователя'
				});
			});
			this.user = Object.assign({}, this.passportUsers[0]);

			await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка филиалов'
				});
			});

			await this.getOffices();
			
			let userOffices = [];

			if (this.staffOffices && this.staffOffices.length) {
				userOffices = this.staffOffices[0].offices;
			}

			for (let i = 0; i < this.helpdeskBranches.length; i++) {
				if (userOffices.includes(this.helpdeskBranches[i].id)) {
					this.userOffices.push({
						id: this.helpdeskBranches[i].id,
						name: this.helpdeskBranches[i].name,
					});
				} else {
					this.officesOptions.push({
						id: this.helpdeskBranches[i].id,
						name: this.helpdeskBranches[i].name,
					});
				}
			}

			this.loaded = true;
		}
	},
};
</script>

<style>
</style>
